import React from 'react';
import Search from './Search';
import Main from './Main';

export function Geocities({ route = 'main', goMain }) {
  if (route === 'main') return <Main />;
  else return <Main />;
}

export default Geocities;
