// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: 'Noto Sans TC', sans-serif;\n  font-size: 14px;\n}\n\n*,\n*:before,\n*:after,\n*:placeholder-shown {\n  font-family: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/font.css"],"names":[],"mappings":"AAEA;EACE,mCAAmC;EACnC,kCAAkC;EAClC,uCAAuC;EACvC,eAAe;AACjB;;AAEA;;;;EAIE,oBAAoB;AACtB","sourcesContent":["@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);\n\nbody {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: 'Noto Sans TC', sans-serif;\n  font-size: 14px;\n}\n\n*,\n*:before,\n*:after,\n*:placeholder-shown {\n  font-family: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
