import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { WindowDropDowns, Google } from 'components';
import dropDownData from './dropDownData';
import ie from 'assets/windowsIcons/ie-paper.png';
import printer from 'assets/windowsIcons/17(32x32).png';
import go from 'assets/windowsIcons/290.png';
import wernotext from 'assets/wer/wernotext.png';
import dutch from 'assets/wer/dutch.webp';
import fire from 'assets/90s/fire.gif';
import firebar from 'assets/90s/firebar.gif';
import dean from 'assets/90s/dean-banner.gif';
import neopets from 'assets/90s/neopets.gif';
import anime100 from 'assets/90s/anime100red.gif';
import hotmail from 'assets/90s/hotmail3.gif';
import russianbride from 'assets/90s/russianbrides.gif';
import links from 'assets/windowsIcons/links.png';
import search from 'assets/windowsIcons/299(32x32).png';
import favorite from 'assets/windowsIcons/744(32x32).png';
import back from 'assets/windowsIcons/back.png';
import earth from 'assets/windowsIcons/earth.png';
import edit from 'assets/windowsIcons/edit.png';
import forward from 'assets/windowsIcons/forward.png';
import history from 'assets/windowsIcons/history.png';
import home from 'assets/windowsIcons/home.png';
import mail from 'assets/windowsIcons/mail.png';
import msn from 'assets/windowsIcons/msn.png';
import refresh from 'assets/windowsIcons/refresh.png';
import stop from 'assets/windowsIcons/stop.png';
import windows from 'assets/windowsIcons/windows.png';
import dropdown from 'assets/windowsIcons/dropdown.png';
import frogright from 'assets/pepehub/dancfrogright.gif';
import frogleft from 'assets/pepehub/dancfrogleft.gif';
import ass from 'assets/pepehub/pepeass.jpg';
import frogbar from 'assets/pepehub/frogbar.gif';
import buynow from 'assets/pepehub/Buy_Logo.gif';
import kermit from 'assets/pepehub/kermit.gif';
import kisstoads from 'assets/pepehub/banner3.gif';
import eurobride from 'assets/pepehub/console111.gif';
import sexypepe from 'assets/pepehub/sexypepe.jpg';
import frog from 'assets/pepehub/frog.gif';
import hansen from 'assets/pepehub/hansen.jpg';
import lolicon from 'assets/90s/lolicon15.gif';
import sexy from 'assets/90s/sexy88x31.gif';
import walkingleft from 'assets/90s/walking2.gif';
import walkingright from 'assets/90s/walking.gif';
import ned from 'assets/90s/nedscape_now.gif';
import virus from 'assets/90s/virusalert.gif';
import headerimg from 'assets/whitepaper/whitepaperheader.png';

function Whitepaper({ onClose }) {
  const [state, setState] = useState({
    route: 'main',
    query: '',
  });

  const [showHansen, setShowHansen] = useState(false);

  function goMain() {
    setState({
      route: 'main',
      query: '',
    });
  }
  function onClickOptionItem(item) {
    switch (item) {
      case 'Close':
        onClose();
        break;
      case 'Home Page':
      case 'Back':
        goMain();
        break;
      default:
    }
  }

  function toggleHansen() {
    setShowHansen(!showHansen);
  }

  {
    showHansen && (
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={hansen}
          alt="Hansen"
          style={{ maxWidth: '90%', maxHeight: '90%' }}
          onClick={() => setShowHansen(false)}
        />
      </div>
    );
  }

  useEffect(() => {
    const frogImage = document.querySelector('.frog-image');
    if (frogImage) {
      frogImage.addEventListener('click', toggleHansen);
    }

    return () => {
      if (frogImage) {
        frogImage.removeEventListener('click', toggleHansen);
      }
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://terminal.jup.ag/main-v2.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Div>
      <section className="ie__toolbar">
        <div className="ie__options">
          <WindowDropDowns
            items={dropDownData}
            onClickItem={onClickOptionItem}
            height={21}
          />
        </div>
        <img className="ie__windows-logo" src={windows} alt="windows" />
      </section>
      <section className="ie__function_bar">
        <div
          onClick={goMain}
          className={`ie__function_bar__button${
            state.route === 'main' ? '--disable' : ''
          }`}
        >
          <img className="ie__function_bar__icon" src={back} alt="" />
          <span className="ie__function_bar__text">Back</span>
          <div className="ie__function_bar__arrow" />
        </div>
        <div className="ie__function_bar__button--disable">
          <img className="ie__function_bar__icon" src={forward} alt="" />
          <div className="ie__function_bar__arrow" />
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon--margin-1" src={stop} alt="" />
        </div>
        <div className="ie__function_bar__button">
          <img
            className="ie__function_bar__icon--margin-1"
            src={refresh}
            alt=""
          />
        </div>
        <div className="ie__function_bar__button" onClick={goMain}>
          <img className="ie__function_bar__icon--margin-1" src={home} alt="" />
        </div>
        <div className="ie__function_bar__separate" />
        <div className="ie__function_bar__button">
          <img
            className="ie__function_bar__icon--normalize "
            src={search}
            alt=""
          />
          <span className="ie__function_bar__text">Search</span>
        </div>
        <div className="ie__function_bar__button">
          <img
            className="ie__function_bar__icon--normalize"
            src={favorite}
            alt=""
          />
          <span className="ie__function_bar__text">Favorites</span>
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon" src={history} alt="" />
        </div>
        <div className="ie__function_bar__separate" />
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon--margin-1" src={mail} alt="" />
          <div className="ie__function_bar__arrow--margin-11" />
        </div>
        <div className="ie__function_bar__button">
          <img
            className="ie__function_bar__icon--margin12"
            src={printer}
            alt=""
          />
        </div>
        <div className="ie__function_bar__button--disable">
          <img className="ie__function_bar__icon" src={edit} alt="" />
        </div>
        <div className="ie__function_bar__button">
          <img className="ie__function_bar__icon--margin12" src={msn} alt="" />
        </div>
      </section>
      <section className="ie__address_bar">
        <div className="ie__address_bar__title">Address</div>
        <div className="ie__address_bar__content">
          <img src={ie} alt="ie" className="ie__address_bar__content__img" />
          <div className="ie__address_bar__content__text">
            {`https://IndianDudesWriteWhitepapersWithChatGPT&ChargeYouMoneyForIt.com`}
          </div>
          <img
            src={dropdown}
            alt="dropdown"
            className="ie__address_bar__content__img"
          />
        </div>
        <div className="ie__address_bar__go">
          <img className="ie__address_bar__go__img" src={go} alt="go" />
          <span className="ie__address_bar__go__text">Go</span>
        </div>
        <div className="ie__address_bar__separate" />
        <div className="ie__address_bar__links">
          <span className="ie__address_bar__links__text">Links</span>
          <img
            className="ie__address_bar__links__img"
            src={links}
            alt="links"
          />
        </div>
      </section>
      <div className="ie__content">
        <div className="ie__content__inner">
          <div className="ie__content">
            <div
              className="ie__content__inner"
              dangerouslySetInnerHTML={{
                __html: `
                <!-- Attach the loading script in your <head /> -->
                <script src='https://terminal.jup.ag/main-v2.js'></script>
            
                <table style="width: 100%; height: 100%;">
                  <tr>
                    <td style="background-color: #32CD32; width: 10%; padding: 5px; vertical-align: top;">
                </td>
                    <td style="background-color: #fff; width: 35%; padding: 10px;">
                      <h1>$WER - Whitepaper</h1>
                      <marquee><h2>$WER - It's just a frog with bad luck bro. -------  $WER - It's just a frog with bad luck bro. ------- $WER - It's just a frog with bad luck bro. ------- $WER - It's just a frog with bad luck bro. ------- $WER - It's just a frog with bad luck bro. ------- $WER - It's just a frog with bad luck bro. -------       </h2></marquee>
                      <p>Apparently you need a whitepaper to do anything these days. I blame the Europoors. But this is the $WER whitepaper.</p><br /><br />

                      <strong>Mission:</strong><br />
                      <img src="${headerimg}"/><br /><br />
                      <p>$WER is a memecoin about a frog that has bad luck in crypto, and is dutch. Which isn't a real language. That's it.</p><br />
                      <p>What does it do?</p><br /><br />
                      <p>It captures the sorrow and melancholy of the human condition in crypto. Reminding us of our fallible nature, the pain and suffering we experience to make this industry grow, and acts as an outlet, a true channel by which human expression and comradery can be experienced and shared in the cathartic cleansing of memes.</p><br /><br />
                      <p>REALLY?!?!</p>  <p>...Fuck no. It's a memecoin about a frog who has probleeems.</p><br /><br />
                      <p>Everyone is tired of memecoins that are happy and cute and celebrated "oh yay, look at me, I'm a special little memecoin who can do no wrong.</p><br />
                      <p>So Wer is the anti-memecoin. Things do go wrong for Wer. His blockchain crashes, he gets rugged, he buys bad coins, he invests in dumb presales. Wer is an absolute mouth-breathing, glue-sniffing, crayon-eating, incel left-curver, just like you!</p><br /><br />

                      <strong>Tokenomics:</strong><br />
                      <p>There are a total of 1B $WER tokens.</p><br /><br />

                      <p>700M were put into the initial Solana liquidity pool and 10% of LP tokens were burned. Remaining LP tokens are in a team multisig.</p><br /><br />
                  
                      <p>300M are reserved for the $WER team and sit in a multisig wallet. They'll vest over 3 years.</p><br />
                      <p>When the team finds a reliable timebased vesting tool we'll move the team reserve to that</p><br /><br />

                      <p>Over time a portion of the team's reserve will also be used for various ecosystem rewards and grants, or to provide migrated versions of $WER to other chains, such as BASE.</p><br /><br />

                      <strong>Memes:</strong><br />
                      <p>$Wer is fueled by stickers and memes that chronicle his many screw-ups in daily life. A small portion of the team reserve will be used to reward the best artists and mememakers in the community - and no, your shitty templated mememaker memes that you've entered into 300 memecontests don't count. We're talking real, high-quality, hand-crafted, artisanal memes, preferrably from the Mémè region of France, because otherwise it's really just sparkling comic strips.</p><br /><br />
                  
                      <strong>But, like, are you going to build stuff?</strong><br /><br />
                      <p>....Dude, it's literally just a sad frog.</p><br /><br /><br />

                      <strong>Should I invest my life savings into this?</strong><br />
                      <p>As much as I'm sure that Wer would love for you to cash your next McDonald's wage cuck check into the ecosystem, that seems kinda dumb...</p><br /><br />

                      <strong>But what if I think this is the next Bitcoin?!</strong><br />
                      <p>Bruh... it is a frog who hebben probleems</p><br /><br />

                      <strong>Is the team a super secret darkshadowy supercoder cabal that is here to make me rich?</strong><br />
                      <p>Frog. Mit. Probleems.</p><br /><br /><br /><br />
                  
                      <p></p><strong>Client Testimonials</strong></p><br /><br /><br /><br />
                      <p>"The most egalitarian thing I've ever seen!" -- Jesus probably</p><br /><br />
                      <p>"When it crashes, it's not really crashing, it is simply too decentralized to be understood." --0xMurt</p><br /><br />
                      <p>"I like it!" --Vitalik's second cousin's second-grade teacher's landlord's nephew (twice-removed)</p><br /><br />
                    </td>
                    <td style="background-color: #32CD32; width: 10%; padding: 5px;">
                    </td>
                  </tr>
                </table>
              `,
              }}
            />
          </div>
        </div>
      </div>
      <footer className="ie__footer">
        <div className="ie__footer__status">
          <img className="ie__footer__status__img" src={ie} alt="" />
          <span className="ie__footer__status__text">Done</span>
        </div>
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__block" />
        <div className="ie__footer__right">
          <img className="ie__footer__right__img" src={earth} alt="" />
          <span className="ie__footer__right__text">Internet</span>
          <div className="ie__footer__right__dots" />
        </div>
      </footer>
    </Div>
  );
}

const Div = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background: linear-gradient(to right, #edede5 0%, #ede8cd 100%);

  .ie__toolbar {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 100%;
    height: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    flex-shrink: 0;
  }
  .ie__options {
    height: 23px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    padding-left: 2px;
    flex: 1;
  }
  .ie__windows-logo {
    height: 100%;
    border-left: 1px solid white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ie__function_bar {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 11px;
    padding: 1px 3px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ie__function_bar__button {
    display: flex;
    height: 100%;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 3px;
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 -1px 1px rgba(0, 0, 0, 0.1);
    }
    &:hover:active {
      border: 1px solid rgb(185, 185, 185);
      background-color: #dedede;
      box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.7);
      color: rgba(255, 255, 255, 0.7);
      & > * {
        transform: translate(1px, 1px);
      }
    }
  }
  .ie__function_bar__button--disable {
    filter: grayscale(1);
    opacity: 0.7;
    display: flex;
    height: 100%;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  .ie__function_bar__text {
    margin-right: 4px;
  }
  .ie__function_bar__icon {
    height: 30px;
    width: 30px;
    &--normalize {
      height: 22px;
      width: 22px;
      margin: 0 4px 0 1px;
    }
    &--margin12 {
      height: 22px;
      width: 22px;
      margin: 0 1px 0 2px;
    }
    &--margin-1 {
      margin: 0 -1px;
      height: 30px;
      width: 30px;
    }
  }
  .ie__function_bar__separate {
    height: 90%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0 2px;
  }
  .ie__function_bar__arrow {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 4px;
    &:before {
      content: '';
      display: block;
      border-width: 3px 3px 0;
      border-color: #000 transparent;
      border-style: solid;
    }
  }
  .ie__function_bar__arrow--margin-11 {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 1px 0 -1px;
    &:before {
      content: '';
      display: block;
      border-width: 3px 3px 0;
      border-color: #000 transparent;
      border-style: solid;
    }
  }
  .ie__address_bar {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    height: 22px;
    font-size: 11px;
    display: flex;
    align-items: center;
    padding: 0 2px 2px;
    box-shadow: inset 0 -2px 3px -1px #2d2d2d;
  }
  .ie__address_bar__title {
    line-height: 100%;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px;
  }
  .ie__address_bar__content {
    border: rgba(122, 122, 255, 0.6) 1px solid;
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    background-color: white;
    position: relative;
    &__img {
      width: 14px;
      height: 14px;
    }
    &__img:last-child {
      width: 15px;
      height: 15px;
      right: 1px;
      position: absolute;
    }
    &__img:last-child:hover {
      filter: brightness(1.1);
    }
    &__text {
      position: absolute;
      white-space: nowrap;
      left: 16px;
      right: 17px;
      overflow: hidden;
    }
  }
  .ie__address_bar__go {
    display: flex;
    align-items: center;
    padding: 0 18px 0 5px;
    height: 100%;
    position: relative;
    &__img {
      height: 95%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      margin-right: 3px;
    }
  }
  .ie__address_bar__links {
    display: flex;
    align-items: center;
    padding: 0 18px 0 5px;
    height: 100%;
    position: relative;
    &__img {
      position: absolute;
      right: 2px;
      top: 3px;
      height: 5px;
      width: 8px;
    }
    &__text {
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .ie__address_bar__separate {
    height: 100%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 rgba(255, 255, 255, 0.7);
  }
  .ie__content {
    flex: 1;
    overflow: auto;
    padding-left: 1px;
    border-left: 1px solid #6f6f6f;
    background-color: #f1f1f1;
    position: relative;
  }
  .ie__content__inner {
    position: relative;
    min-height: 800px;
    min-width: 800px;
    width: 100%;
    height: 100%;
  }
  .ie__footer {
    height: 20px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 3px rgba(50, 50, 50, 0.8);
    background-color: rgb(236, 233, 216);
    display: flex;
    align-items: center;
    padding-top: 2px;
  }
  .ie__footer__status {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 2px;
    &__text {
      font-size: 11px;
    }
    &__img {
      height: 14px;
      width: 14px;
      margin-right: 3px;
    }
  }
  .ie__footer__block {
    height: 85%;
    width: 22px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: inset 1px 0 rgba(255, 255, 255, 0.7);
  }
  .ie__footer__right {
    display: flex;
    align-items: center;
    width: 150px;
    height: 80%;
    border-left: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: inset 1px 0 rgba(255, 255, 255, 0.7);
    padding-left: 5px;
    position: relative;
    &__text {
      font-size: 11px;
    }
    &__img {
      height: 14px;
      width: 14px;
      margin-right: 3px;
    }
    &__dots {
      position: absolute;
      right: 11px;
      bottom: -1px;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0px rgba(0, 0, 0, 0.25), 5.5px 0px rgba(0, 0, 0, 0.25),
        9px 0px rgba(0, 0, 0, 0.25), 5.5px -3.5px rgba(0, 0, 0, 0.25),
        9px -3.5px rgba(0, 0, 0, 0.25), 9px -7px rgba(0, 0, 0, 0.25),
        3px 1px rgba(255, 255, 255, 1), 6.5px 1px rgba(255, 255, 255, 1),
        10px 1px rgba(255, 255, 255, 1), 10px -2.5px rgba(255, 255, 255, 1),
        10px -6px rgba(255, 255, 255, 1);
    }
  }
`;

export default Whitepaper;
